<template>
  <a-modal
    width="400px"
    v-model="isShow"
    centered
    :mask-closable="false"
    :closable="false"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="handleSubmit">
        确定
      </a-button>
    </template>

    <a-spin :spinning="loading">
      <a-form-item>
        <div> 您好，{{ name }}，欢迎登陆IOT物联网连接管理平台，勾选下列协议并点击确定进入平台。</div>
        <a-tooltip
          placement="topLeft"
          :title="isDisabledCheckPlatformServiceAgreementTip ? '请联系管理员维护平台服务协议！' : ''"
          arrow-point-at-center
        >
          <a-checkbox
            @change="onChange"
            :disabled="isDisabledCheckPlatformServiceAgreementTip"
          >
            <span>同意</span>
            <a :href="agreement.resource_url" target="_blank">
              {{ loginAgreementName }}
            </a>
          </a-checkbox>
        </a-tooltip>
      </a-form-item>
    </a-spin>
  </a-modal>
</template>

<script>
import { findUsingPlatformServiceAgreement, updateAgentPlatformServiceAgreement } from '@/api/agreement'
import { findHomeAgentName } from '@/api/agent'

export default {
  name: 'ShowAgreement',
  data() {
    return {
      loading: true,
      isDisabledCheckPlatformServiceAgreementTip: false,
      loginAgreementName: '',
      agreement: {},
      submitting: false,
      isReadAgreement: false,
      name: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchPlatformServiceAgreement()
    this.fetchAgentName()
  },
  methods: {
    onChange(v) {
      this.isReadAgreement = v.target.checked
    },

    closeModal() {
      this.isShow = false
    },

    handleSubmit() {
      if (!this.isReadAgreement) {
        this.$warning({
          title: this.readAgreementWarningTitle,
          content: ''
        })
        return
      }

      this.submitting = true
      updateAgentPlatformServiceAgreement({ agreement_id: this.agreement.id }).then(res => {
        if (res.code === 0) {
          this.$store.dispatch('SetIsShowAgreement', false)
        }
        this.submitting = false
      })
    },

    // 获取平台服协议
    fetchPlatformServiceAgreement() {
      this.isDisabledCheckPlatformServiceAgreementTip = true
      this.loading = true
      findUsingPlatformServiceAgreement().then((res) => {
        if (res.code === 0) {
          this.isDisabledCheckPlatformServiceAgreementTip = false
          this.agreement = res.data
          this.loginAgreementName = `《${this.agreement.name}》`
          this.readAgreementWarningTitle = '您还未同意' + this.loginAgreementName
        } else {
          this.readAgreementWarningTitle = '请勾选同意服务协议'
        }
        this.loading = false
      })
    },

    fetchAgentName() {
      findHomeAgentName(this.$store.getters.userAgentId).then((res) => {
        if (res.code === 0) {
          this.name = res.data.name
        }
      })
    }
  }
}
</script>
